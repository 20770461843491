<template>
  <div class="">
    <b-card v-if="data.file_piagam[0] != null">
      <table class="table reponsive">
        <tr>
          <th>No</th>
          <th>Nama</th>
          <th>File</th>
        </tr>
        <tr v-for="(item, i) in data.file_piagam" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>
            <a
              :href="apiFile + item.file"
              target="_blank"
              class="btn btn-primary"
              >Lihat</a
            >
          </td>
        </tr>
      </table>
    </b-card>
    <b-card v-else>
      <center>Anda Tidak Mengisi Piagam</center>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
  },
  props: ["data"],
};
</script>

<style></style>
