<template>
  <div class="">
    <b-card>
      <h3 class="text-center">Nilai Raport Semester 1-5</h3>
      <br />
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>Mapel</b-th>
            <b-th>Semester 1</b-th>
            <b-th>Semester 2</b-th>
            <b-th>Semester 3</b-th>
            <b-th>Semester 4</b-th>
            <b-th>Semester 5</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, i) in data.nilai_semester" :key="i">
            <b-td> {{ item.nama_mapel }} </b-td>
            <b-td v-for="(data, index) in item.nilai" :key="index">{{ Math.floor(data.nilai) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <!-- <b-card v-for="(item, i) in data.nilai_semester" :key="i">
      <h3>{{ item.nama_mapel }}</h3>
      <hr />
      <div class="span" v-for="(data, index) in item.nilai" :key="index">
        <h3>Semester {{ data.type_semester }}</h3>
        <h3>Nilai : {{ data.nilai }}</h3>
        <hr />
      </div>
    </b-card> -->
  </div>
</template>

<script>
import { BCard, BTableSimple, BTbody, BThead, BTfoot, BTr, BTd, BTh } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
  },
  props: ["data"],
};
</script>

<style></style>
